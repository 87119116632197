.default{
  background-color: none;
  color: none;
}

.notCall{
  min-width: 83px;
  min-height: 44px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f1f0;
  color: #a3a3a3;
}

.payCall{
  min-width: 83px;
  min-height: 44px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #02d15c;
  color: #ffffff;
}